import { useEffect, useState } from "react";
import { Box, Flex, Icon } from "@storyofams/react-ui";
import { motion } from "framer-motion";
import { range } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import styled, { css } from "styled-components";
import { Appear } from "~/components";
import {
  Button,
  Subtext,
  Link as LinkComponent,
  Title,
  EditorController,
  Result,
} from "~/components/preview";
import { ReactComponent as refresh } from "~/components/preview/Icon/library/refresh.svg";
import config from "~/config";
import { useFlow, useOptionsForm, useSdk } from "~/hooks";
import { messages } from "./messages";
import { useBillingWrapper } from "~/lib";
import ContentBlockModal from "~/components/preview/ContentBlockModal";
import ContentBlocksList from "~/components/preview/ContentBlocksList";
import { useQueryClient } from "react-query";

const MotionBox = motion(Box) as any;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
      ...config.transition,
    },
  },
};

const getDefaultValues = (values) => ({
  resultsTitle: values?.resultsTitle || "",
  resultsDescription: values?.resultsDescription || "",
});

const ResetLink = styled.div<{ disabled?: boolean }>`
  svg {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
  }

  &:hover svg {
    transform: rotate(30deg);
  }

  &:active svg,
  &:focus svg {
    transform: rotate(360deg);
  }

  ${(p) =>
    !!p.disabled &&
    css`
      svg {
        transform: rotate(360deg) !important;
      }
    `}
`;

export const ResultsForm = () => {
  const intl = useIntl();
  const { data } = useFlow();

  const { control } = useOptionsForm({
    getDefaultValues,
    type: "flow",
  });

  const billingWrapper = useBillingWrapper({});

  const [showContentBlockModal, setShowContentBlockModal] = useState(false);
  const [contentBlockLocation, setContentBlockLocation] = useState("top");
  const [contentBlockId, setContentBlockId] = useState(null);
  const [resultsContentBlocks, setResultsContentBlocks] = useState(null as any);

  const queryClient = useQueryClient();

  const sdk = useSdk();

  useEffect(() => {
    if (data?.flows?.[0]?.resultsContentBlocks && !resultsContentBlocks) {
      setResultsContentBlocks(
        JSON.parse(data?.flows?.[0]?.resultsContentBlocks)
      );
    }
  }, [data?.flows?.[0]?.resultsContentBlocks]);

  const openContentBlockModal = (location) => {
    setContentBlockLocation(location);
    billingWrapper(setShowContentBlockModal)(true);
  };

  const saveContentBlock = async (location, id, contentBlock) => {
    if (id && data?.flows?.[0]?.id) {
      const existingContentBlocks = resultsContentBlocks?.[location] ?? [];
      const blockIndex = existingContentBlocks.findIndex(
        (cb) => cb?.id && cb.id === id
      );

      if (blockIndex >= 0) {
        existingContentBlocks[blockIndex] = contentBlock;
      } else {
        existingContentBlocks.push(contentBlock);
      }
      const result = await sdk.updateOneFlow({
        input: {
          id: data.flows[0].id,
          update: {
            resultsContentBlocks: JSON.stringify({
              ...resultsContentBlocks,
              [location]: existingContentBlocks,
            }),
          },
        },
      });

      queryClient.invalidateQueries(["container", { id: data?.id }]);

      if (result?.updateOneFlow?.resultsContentBlocks) {
        const newResultsContentBlocks = JSON.parse(
          result?.updateOneFlow?.resultsContentBlocks
        );

        setResultsContentBlocks(newResultsContentBlocks);
      }
    }
  };

  const updateContentBlocks = async (contentBlocks) => {
    if (data?.flows?.[0]?.id) {
      const result = await sdk.updateOneFlow({
        input: {
          id: data.flows[0].id,
          update: {
            resultsContentBlocks: JSON.stringify(contentBlocks),
          },
        },
      });

      queryClient.invalidateQueries(["container", { id: data?.id }]);

      if (result?.updateOneFlow?.resultsContentBlocks) {
        setResultsContentBlocks(
          JSON.parse(result?.updateOneFlow?.resultsContentBlocks)
        );
      }
    }
  };

  return (
    <>
      <MotionBox
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={config.transition}
        textAlign="center"
        className="results-section"
      >
        <span className="results-container">
          {!!data?.flows?.[0]?.enableRestart && (
            <motion.div
              className="start-over-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0, ...config.transition }}
            >
              <Box className="start-over" mb={3}>
                <ResetLink
                  className="start-over-link"
                  as={LinkComponent}
                  disabled
                >
                  <Icon
                    className="start-over-icon"
                    icon={refresh as any}
                    css={{ marginInlineEnd: "8px" }}
                  />{" "}
                  <FormattedMessage {...messages.restart} />
                </ResetLink>
              </Box>
            </motion.div>
          )}

          <ContentBlocksList
            contentBlocks={resultsContentBlocks}
            update={updateContentBlocks}
            setContentBlockId={setContentBlockId}
            location="top"
            openContentBlockModal={openContentBlockModal}
          />

          <Box my={3}>
            <Button
              className="button-secondary"
              size="small"
              variant="secondary"
              primaryColor="#a79efe"
              plus={true}
              onClick={() => {
                setContentBlockId(null);
                openContentBlockModal("top");
              }}
              style={{
                border: "2px dashed #a79efe",
                backgroundColor: "transparent",
                borderRadius: "15px",
              }}
            >
              Add a Dynamic Content Block
            </Button>
          </Box>

          {!data?.flows?.[0]?.hideDefaultRecommendations && (
            <>
              <Appear className="results-heading-section">
                <Box
                  className="results-heading-container"
                  maxWidth="790px"
                  mx="auto"
                >
                  <EditorController
                    name="resultsTitle"
                    className="results-heading"
                    control={control}
                    element={Title}
                    dir="auto"
                    placeholder={intl.formatMessage(messages.resultsTitle)}
                  />

                  <Box mt={2} mb={5}>
                    <EditorController
                      name="resultsDescription"
                      className="results-subheading"
                      control={control}
                      element={Subtext}
                      dir="auto"
                      placeholder={intl.formatMessage(messages.resultsSubtext)}
                    />
                  </Box>
                </Box>
              </Appear>

              <motion.div
                className="results-top-3-products-container"
                variants={container}
                initial="hidden"
                animate="show"
              >
                <Flex
                  className="results-top-3-products"
                  flexWrap="wrap"
                  mx={-1}
                  justifyContent="center"
                >
                  {range(data?.flows?.[0]?.resultsAmount || 3).map((idx) => (
                    <Result
                      resultsPerRowMobile={
                        data?.flows?.[0]?.resultsPerRowMobile ?? 1
                      }
                      resultsPerRow={data?.flows?.[0]?.resultsPerRow ?? 3}
                      key={idx}
                      idx={idx}
                    />
                  ))}
                </Flex>

                {!!data?.flows?.[0]?.enableAddAll && (
                  <Button
                    className="button button-add-all-to-cart"
                    primaryColor={data?.flows?.[0]?.primaryColor || undefined}
                    size="large"
                    borderRadius="xs"
                    mt={5}
                    disabled
                  >
                    {messages?.addAll ? (
                      <FormattedMessage {...messages.addAll} />
                    ) : (
                      "Add all to cart"
                    )}
                  </Button>
                )}
              </motion.div>

              {!!data?.flows?.[0]?.enableMoreResults && (
                <motion.div
                  className="button-view-more-container"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0, ...config.transition }}
                >
                  <Button
                    className="button-secondary button-view-more"
                    size="small"
                    variant="secondary"
                    mt={5}
                    disabled
                  >
                    <FormattedMessage {...messages.viewMore} />
                  </Button>
                </motion.div>
              )}
            </>
          )}
        </span>

        {!data?.flows?.[0]?.hideDefaultRecommendations && (
          <>
            <ContentBlocksList
              contentBlocks={resultsContentBlocks}
              update={updateContentBlocks}
              setContentBlockId={setContentBlockId}
              location="bottom"
              openContentBlockModal={openContentBlockModal}
            />

            <Box my={3}>
              <Button
                className="button-secondary"
                size="small"
                variant="secondary"
                primaryColor="#a79efe"
                plus={true}
                onClick={() => {
                  setContentBlockId(null);
                  openContentBlockModal("bottom");
                }}
                style={{
                  border: "2px dashed #a79efe",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                }}
              >
                Add a Dynamic Content Block
              </Button>
            </Box>
          </>
        )}
      </MotionBox>

      <ContentBlockModal
        open={showContentBlockModal}
        onClose={() => setShowContentBlockModal(false)}
        onOpen={() => setShowContentBlockModal(true)}
        nodes={data?.flows?.[0]?.nodes}
        contentBlocks={resultsContentBlocks}
        location={contentBlockLocation}
        id={contentBlockId}
        save={saveContentBlock}
      />
    </>
  );
};
