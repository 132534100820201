import { Section } from "~/components/Section";
import { Controller, useController, useWatch } from "react-hook-form";
import { useOptionsForm } from "~/hooks";
import {
  BlockStack,
  Button,
  Checkbox,
  Collapsible,
  Icon,
  InlineStack,
  RadioButton,
  RangeSlider,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import { FlowNodeType } from "~/graphql/sdk";
import {
  AppsIcon,
  DeleteIcon,
  DesktopIcon,
  MobileIcon,
  PaintBrushFlatIcon,
  PlusIcon,
  SettingsIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ColorInput } from "~/components/ColorInput";

export const OPTIONS = [
  { label: "Single choice - text only", value: FlowNodeType.Simple },
  { label: "Single choice - with emoji", value: FlowNodeType.Emoji },
  { label: "Single choice - with image", value: FlowNodeType.Image },
  {
    label: "Multi choice - text only",
    value: FlowNodeType.SimpleMulti,
  },
  {
    label: "Multi choice - with emoji",
    value: FlowNodeType.EmojiMulti,
  },
  {
    label: "Multi choice - with image",
    value: FlowNodeType.ImageMulti,
  },
  {
    label: "Input - single-line text",
    value: FlowNodeType.InputOneLineText,
  },
  {
    label: "Input - multi-line text",
    value: FlowNodeType.InputMultiLineText,
  },
  {
    label: "Transition Screen",
    value: FlowNodeType.Transition,
  },
  // {
  //   label: "Input - calendar",
  //   value: FlowNodeType.InputCalendar,
  // },
  // {
  //   label: "Input - slider",
  //   value: FlowNodeType.InputSlider,
  // },
];

export const INPUT_FIELD_TYPE_OPTIONS = [
  { label: "text", value: "text" },
  { label: "number", value: "number" },
];

const getDefaultValues = (values) => {
  const pageSettingsJSON = values?.pageSettings;
  const pageSettings = pageSettingsJSON ? JSON.parse(pageSettingsJSON) : {};

  return {
    type: values?.type || FlowNodeType.InputMultiLineText,
    isRequired:
      typeof values?.isRequired !== "undefined" ? !!values.isRequired : true,
    propertyID: values?.propertyID || "",
    klaviyoID: values?.klaviyoID || "",
    mailchimpID: values?.mailchimpID || "",
    inputFieldType: values?.inputFieldType || "text",
    maxLength: values?.maxLength || "",
    minLength: values?.minLength || "",
    inputOne_buttonTextOverride:
      pageSettings?.inputOne_buttonTextOverride ?? "",
    conditions: pageSettings?.conditions ?? "{}",
    topWidthDesktopType: pageSettings?.topWidthDesktopType ?? "100%",
    topWidth: pageSettings?.topWidth ?? "1200",
    bottomWidthDesktopType: pageSettings?.bottomWidthDesktopType ?? "100%",
    bottomWidth: pageSettings?.bottomWidth ?? "1200",
    topWidthMobileType: pageSettings?.topWidthMobileType ?? "100%",
    topWidthMobile: pageSettings?.topWidthMobile ?? "1200",
    bottomWidthMobileType: pageSettings?.bottomWidthMobileType ?? "100%",
    bottomWidthMobile: pageSettings?.bottomWidthMobile ?? "1200",
    questionAlignment: pageSettings?.questionAlignment ?? "left",
    questionFontSize: pageSettings?.questionFontSize ?? "32",
    buttonColor: pageSettings?.buttonColor ?? "#000",
    useDefaultColorForButton: pageSettings?.useDefaultColorForButton ?? "yes",
    buttonTextColor: pageSettings?.buttonTextColor ?? "#fff",
    useDefaultTextColorForButton:
      pageSettings?.useDefaultTextColorForButton ?? "yes",
    buttonTextOverride: pageSettings?.buttonTextOverride ?? "",
    buttonFontSize: pageSettings?.buttonFontSize ?? "18",
    buttonHorizontalPadding: pageSettings?.buttonHorizontalPadding ?? "56",
    buttonVerticalPadding: pageSettings?.buttonVerticalPadding ?? "20",
    buttonBorderRadius: pageSettings?.buttonBorderRadius ?? "8",
    buttonWidthType: pageSettings?.buttonWidthType ?? "custom",
    buttonWidth: pageSettings?.buttonWidth ?? "370",
    buttonFontSizeMobile: pageSettings?.buttonFontSizeMobile ?? "18",
    buttonBorderRadiusMobile: pageSettings?.buttonBorderRadiusMobile ?? "8",
    buttonHorizontalPaddingMobile:
      pageSettings?.buttonHorizontalPaddingMobile ?? "56",
    buttonVerticalPaddingMobile:
      pageSettings?.buttonVerticalPaddingMobile ?? "20",
    buttonWidthMobileType: pageSettings?.buttonWidthMobileType ?? "100%",
    buttonWidthMobile: pageSettings?.buttonWidthMobile ?? "345",
    buttonAlignment: pageSettings?.buttonAlignment ?? "left",
  };
};

const InputFieldSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="inputFieldType"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Input Field Type"
            options={INPUT_FIELD_TYPE_OPTIONS}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
          />
        )}
      />

      <Controller
        control={control}
        name="maxLength"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Max. Characters"
            autoComplete="off"
            placeholder="leave blank for no limit"
            value={field.value}
            name={field.name}
            type="number"
            onChange={field.onChange}
            helpText="Limit the number of characters that can be entered."
          />
        )}
      />

      <Controller
        control={control}
        name="minLength"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Min. Characters"
            autoComplete="off"
            placeholder="leave blank for no minimum"
            value={field.value}
            name={field.name}
            type="number"
            onChange={field.onChange}
            helpText="Require a minimum number of characters to be entered."
          />
        )}
      />
    </Section>
  );
};

const ButtonSettings = ({ control }) => {
  const useDefaultColorForButton = useWatch({
    name: "useDefaultColorForButton",
    control,
  });
  const useDefaultTextColorForButton = useWatch({
    name: "useDefaultTextColorForButton",
    control,
  });
  const buttonWidthType = useWatch({
    name: "buttonWidthType",
    control,
  });

  return (
    <Section>
      <Controller
        control={control}
        name="inputOne_buttonTextOverride"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Button Text Override"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="leave empty to use default text"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="buttonAlignment"
        render={({ field: { value, onChange, ...field } }) => (
          <InlineStack
            align="space-between"
            blockAlign="center"
            wrap={false}
            gap="100"
          >
            <RadioButton
              label={<Icon source={TextAlignLeftIcon as any} tone="base" />}
              checked={value === "left"}
              onChange={() => onChange("left")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignCenterIcon as any} tone="base" />}
              checked={value === "center"}
              onChange={() => onChange("center")}
              {...field}
            />
            <RadioButton
              label={<Icon source={TextAlignRightIcon as any} tone="base" />}
              checked={value === "right"}
              onChange={() => onChange("right")}
              {...field}
            />
          </InlineStack>
        )}
      />
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="useDefaultColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Background Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />

        {useDefaultColorForButton === "no" && (
          <Controller
            control={control}
            name="buttonColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <InlineStack
        gap="200"
        align="space-between"
        blockAlign="end"
        wrap={false}
      >
        <Controller
          control={control}
          name="useDefaultTextColorForButton"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Text Color"
              value={value}
              {...field}
              options={[
                { label: "Use default color", value: "yes" },
                { label: "Use custom color", value: "no" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {useDefaultTextColorForButton === "no" && (
          <Controller
            control={control}
            name="buttonTextColor"
            render={({ field: { value, onChange, ...field } }) => (
              <ColorInput value={value} onChange={onChange} {...field} />
            )}
          />
        )}
      </InlineStack>
      <Section
        title={
          (
            <InlineStack
              align="center"
              blockAlign="center"
              gap="100"
              wrap={false}
            >
              <Icon source={DesktopIcon as any} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="buttonFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonBorderRadius"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonWidthType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="buttonWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="buttonHorizontalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonVerticalPadding"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon as any} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="buttonFontSizeMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={8}
              max={50}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonBorderRadiusMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Border Radius"
              value={value}
              onChange={onChange}
              min={0}
              max={60}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Width"
              value={value}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
                { label: "Auto Width", value: "auto" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {buttonWidthType === "custom" && (
          <Controller
            control={control}
            name="buttonWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={780}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="buttonHorizontalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Horizontal Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={500}
              step={5}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="buttonVerticalPaddingMobile"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Vertical Padding"
              value={value}
              onChange={onChange}
              min={0}
              max={150}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {`${value}px`}
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </Section>
  );
};

const QuestionSettings = ({ control }) => {
  return (
    <>
      <Section>
        <Controller
          control={control}
          name="questionAlignment"
          render={({ field: { value, onChange, ...field } }) => (
            <InlineStack
              align="space-between"
              blockAlign="center"
              wrap={false}
              gap="100"
            >
              <RadioButton
                label={<Icon source={TextAlignLeftIcon} tone="base" />}
                checked={value === "left"}
                onChange={() => onChange("left")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignCenterIcon} tone="base" />}
                checked={value === "center"}
                onChange={() => onChange("center")}
                {...field}
              />
              <RadioButton
                label={<Icon source={TextAlignRightIcon} tone="base" />}
                checked={value === "right"}
                onChange={() => onChange("right")}
                {...field}
              />
            </InlineStack>
          )}
        />
        <Controller
          control={control}
          name="questionFontSize"
          render={({ field: { value, onChange, ...field } }) => (
            <RangeSlider
              label="Font Size"
              value={value}
              onChange={onChange}
              min={1}
              max={100}
              step={1}
              output
              suffix={
                <p
                  style={{
                    minWidth: "24px",
                    textAlign: "right",
                  }}
                >
                  {value}px
                </p>
              }
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

const ConditionsSettings = ({ control }) => {
  const { field } = useController({
    name: "conditions",
    control,
    defaultValue: JSON.stringify({}),
  });

  const [conditions, setConditions] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.conditions) {
        return val.conditions;
      }
      return [];
    } catch (error) {
      return [];
    }
  });

  const [showSkip, setShowSkip] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.showSkip) {
        return val.showSkip;
      }
      return "skip";
    } catch (error) {
      return "skip";
    }
  });

  const [anyAll, setAnyAll] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.anyAll) {
        return val.anyAll;
      }
      return "any";
    } catch (error) {
      return "any";
    }
  });

  useEffect(() => {
    try {
      const parsed = JSON.parse(field.value);
      setConditions(parsed?.conditions || []);
      setShowSkip(parsed?.showSkip || "skip");
      setAnyAll(parsed?.anyAll || "any");
    } catch (error) {
      setConditions([]);
      setShowSkip("skip");
      setAnyAll("any");
    }
  }, [field.value]);

  useEffect(() => {
    field.onChange(
      JSON.stringify({
        conditions,
        showSkip,
        anyAll,
      })
    );
  }, [conditions, showSkip, anyAll]);

  const addCondition = () => {
    setConditions((prev) => {
      return [
        ...prev,
        {
          id: uuidv4(),
          propertyId: "",
          verb: "is",
          match: "exactly",
          propertyValue: "",
        },
      ];
    });
  };

  const removeCondition = (id) => {
    setConditions((prev) => {
      return prev.filter((condition) => condition.id !== id);
    });
  };

  return (
    <Section>
      <Text as="p" variant="bodyMd">
        Be default, the page is always shown. You can choose to show/skip it
        based on certain conditions.
      </Text>
      <InlineStack gap="100" align="start" blockAlign="center" wrap={true}>
        <Select
          label=""
          labelHidden
          options={[
            { label: "Skip", value: "skip" },
            { label: "Show", value: "show" },
          ]}
          value={showSkip}
          onChange={(v) => setShowSkip(v)}
        />{" "}
        the page if
        <Select
          label=""
          labelHidden
          options={[
            { label: "any", value: "any" },
            { label: "all", value: "all" },
          ]}
          value={anyAll}
          onChange={(v) => setAnyAll(v)}
        />{" "}
        of the following conditions are met:
      </InlineStack>
      <Button variant="secondary" onClick={addCondition} icon={PlusIcon}>
        Add Condition
      </Button>
      {conditions.map((condition) => {
        return (
          <BlockStack key={condition.id} gap="200">
            <TextField
              label="Property ID"
              labelHidden
              autoComplete=""
              value={condition.propertyId}
              onChange={(v) => {
                setConditions((prev) => {
                  return prev.map((c) => {
                    if (c.id === condition.id) {
                      return {
                        ...c,
                        propertyId: v,
                      };
                    }
                    return c;
                  });
                });
              }}
              placeholder="Property ID"
            />
            <InlineStack
              gap="100"
              align="start"
              blockAlign="center"
              wrap={true}
            >
              <Select
                label="verb"
                labelHidden
                options={[
                  { label: "is", value: "is" },
                  { label: "is not", value: "is_not" },
                ]}
                value={condition.verb}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          verb: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
              />
              <Select
                label="match"
                labelHidden
                options={[
                  { label: "exactly", value: "exactly" },
                  { label: "at least", value: "at_least" },
                  { label: "one of", value: "one_of" },
                  { label: "provided", value: "provided" },
                ]}
                value={condition.match}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          match: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
              />
            </InlineStack>
            {condition.match !== "provided" && (
              <TextField
                label="Property Value(s)"
                autoComplete=""
                labelHidden
                value={condition.propertyValue}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          propertyValue: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
                placeholder="Property Value(s)"
                helpText="If you want to include multiple values, separate them with a comma."
              />
            )}
            <Button
              variant="secondary"
              tone="critical"
              icon={DeleteIcon}
              onClick={() => removeCondition(condition.id)}
            >
              Remove Condition
            </Button>
          </BlockStack>
        );
      })}
    </Section>
  );
};

const GeneralSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="propertyID"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Property ID"
            autoComplete="off"
            placeholder="e.g. first_name, age, skinType"
            value={field.value}
            name={field.name}
            error={field.value.length === 0 ? "Property ID is required" : ""}
            onChange={(v) => field.onChange(v.replace(/\s/g, ""))}
            helpText="This should be a unique identifier for the answer."
          />
        )}
      />
      <Controller
        control={control}
        name="isRequired"
        render={({ field: { value, ...field } }) => (
          <Checkbox label="Required" checked={value} {...field} />
        )}
      />
    </Section>
  );
};

const IntegrationsSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="klaviyoID"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Klaviyo ID"
            autoComplete="off"
            placeholder="e.g. first_name"
            value={field.value}
            name={field.name}
            onChange={(v) => field.onChange(v.trim())}
            helpText="Klaviyo ID for this profile property"
          />
        )}
      />
      <Controller
        control={control}
        name="mailchimpID"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Mailchimp ID"
            autoComplete="off"
            placeholder="e.g. FNAME"
            value={field.value}
            name={field.name}
            onChange={(v) => field.onChange(v.trim())}
            helpText="Mailchimp merge tag for this contact property"
          />
        )}
      />
    </Section>
  );
};

const GeneralDesignSettings = ({ control }) => {
  const topWidthMobileType = useWatch({
    name: "topWidthMobileType",
    control,
  });
  const bottomWidthMobileType = useWatch({
    name: "bottomWidthMobileType",
    control,
  });
  const topWidthDesktopType = useWatch({
    name: "topWidthDesktopType",
    control,
  });
  const bottomWidthDesktopType = useWatch({
    name: "bottomWidthDesktopType",
    control,
  });

  return (
    <>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={DesktopIcon} />
              <span style={{ flex: "1" }}>Desktop</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="topWidthDesktopType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="topWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label=""
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="bottomWidthDesktopType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthDesktopType === "custom" && (
          <Controller
            control={control}
            name="bottomWidth"
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                label=""
                value={value}
                onChange={onChange}
                type="text"
                autoComplete="off"
                connectedLeft={
                  <span
                    style={{
                      position: "relative",
                      top: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  >
                    <RangeSlider
                      label=""
                      value={value}
                      onChange={onChange}
                      min={0}
                      max={1500}
                      step={5}
                      output
                    />
                  </span>
                }
                suffix="px"
                {...field}
              />
            )}
          />
        )}
      </Section>
      <Section
        title={
          (
            <InlineStack
              gap="100"
              align="center"
              blockAlign="center"
              wrap={false}
            >
              <Icon source={MobileIcon} />
              <span style={{ flex: "1" }}>Mobile</span>
            </InlineStack>
          ) as any
        }
      >
        <Controller
          control={control}
          name="topWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Top Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {topWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="topWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="bottomWidthMobileType"
          render={({ field: { value, onChange, ...field } }) => (
            <Select
              label="Bottom Section Width"
              value={value}
              {...field}
              options={[
                { label: "Full Width", value: "100%" },
                { label: "Custom Width", value: "custom" },
              ]}
              onChange={onChange}
              {...field}
            />
          )}
        />
        {bottomWidthMobileType === "custom" && (
          <Controller
            control={control}
            name="bottomWidthMobile"
            render={({ field: { value, onChange, ...field } }) => (
              <RangeSlider
                value={value}
                onChange={onChange}
                label=""
                min={0}
                max={1500}
                step={5}
                output
                suffix={
                  <p
                    style={{
                      minWidth: "24px",
                      textAlign: "right",
                    }}
                  >
                    {`${value}px`}
                  </p>
                }
                {...field}
              />
            )}
          />
        )}
      </Section>
    </>
  );
};

const InputMultiLineTextOptions = () => {
  const form = useOptionsForm({
    getDefaultValues,
    pageSettingsKeys: [
      "inputOne_buttonTextOverride",
      "conditions",
      "topWidth",
      "topWidthDesktopType",
      "bottomWidth",
      "bottomWidthDesktopType",
      "topWidthMobileType",
      "topWidthMobile",
      "bottomWidthMobileType",
      "bottomWidthMobile",
      "questionAlignment",
      "questionFontSize",
      "buttonColor",
      "useDefaultColorForButton",
      "useDefaultTextColorForButton",
      "buttonTextColor",
      "buttonTextOverride",
      "buttonFontSize",
      "buttonHorizontalPadding",
      "buttonVerticalPadding",
      "buttonBorderRadius",
      "buttonWidthType",
      "buttonWidth",
      "buttonFontSizeMobile",
      "buttonBorderRadiusMobile",
      "buttonHorizontalPaddingMobile",
      "buttonVerticalPaddingMobile",
      "buttonWidthMobileType",
      "buttonWidthMobile",
      "buttonAlignment",
    ],
    type: "flowNode",
  });
  const control = form.control;
  const [settings, setSettings] = useState(1);

  return (
    <SettingsContainer>
      <BlockStack align="start" inlineAlign="stretch" gap="200">
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 1 ? "critical" : undefined}
          disclosure={settings === 1 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 1 ? 0 : 1))}
          icon={SettingsIcon}
        >
          General Settings
        </Button>
        <Collapsible
          open={settings === 1}
          id="collapsible-1"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <GeneralSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 2 ? "critical" : undefined}
          disclosure={settings === 2 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 2 ? 0 : 2))}
          icon={AppsIcon}
        >
          Integration Settings
        </Button>
        <Collapsible
          open={settings === 2}
          id="collapsible-2"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <IntegrationsSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 3 ? "critical" : undefined}
          disclosure={settings === 3 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 3 ? 0 : 3))}
          icon={PaintBrushFlatIcon}
        >
          General Design
        </Button>
        <Collapsible
          open={settings === 3}
          id="collapsible-3"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <GeneralDesignSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 4 ? "critical" : undefined}
          disclosure={settings === 4 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 4 ? 0 : 4))}
          icon={PaintBrushFlatIcon}
        >
          Question
        </Button>
        <Collapsible
          open={settings === 4}
          id="collapsible-4"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <QuestionSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 5 ? "critical" : undefined}
          disclosure={settings === 5 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 5 ? 0 : 5))}
          icon={PaintBrushFlatIcon}
        >
          Input Field
        </Button>
        <Collapsible
          open={settings === 5}
          id="collapsible-5"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <InputFieldSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 6 ? "critical" : undefined}
          disclosure={settings === 6 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 6 ? 0 : 6))}
          icon={PaintBrushFlatIcon}
        >
          Button
        </Button>
        <Collapsible
          open={settings === 6}
          id="collapsible-6"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ButtonSettings control={control} />
        </Collapsible>

        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 7 ? "critical" : undefined}
          disclosure={settings === 7 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 7 ? 0 : 7))}
          icon={AppsIcon}
        >
          Skip/Show Conditions
        </Button>
        <Collapsible
          open={settings === 7}
          id="collapsible-7"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ConditionsSettings control={control} />
        </Collapsible>
      </BlockStack>
    </SettingsContainer>
  );
};

export default InputMultiLineTextOptions;

const SettingsContainer = styled.div`
  padding: 15px 5px;

  & .Polaris-Button__Content {
    width: 100%;
    justify-content: space-between !important;
  }
`;
