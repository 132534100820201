import { Section } from "~/components/Section";
import { Controller, useController } from "react-hook-form";
import { useOptionsForm } from "~/hooks";
import {
  BlockStack,
  Button,
  Checkbox,
  Collapsible,
  InlineStack,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import { FlowNodeType } from "~/graphql/sdk";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AppsIcon,
  DeleteIcon,
  PlusIcon,
  SettingsIcon,
} from "@shopify/polaris-icons";

export const OPTIONS = [
  { label: "Single choice - text only", value: FlowNodeType.Simple },
  { label: "Single choice - with emoji", value: FlowNodeType.Emoji },
  { label: "Single choice - with image", value: FlowNodeType.Image },
  {
    label: "Multi choice - text only",
    value: FlowNodeType.SimpleMulti,
  },
  {
    label: "Multi choice - with emoji",
    value: FlowNodeType.EmojiMulti,
  },
  {
    label: "Multi choice - with image",
    value: FlowNodeType.ImageMulti,
  },
  {
    label: "Input - single-line text",
    value: FlowNodeType.InputOneLineText,
  },
  {
    label: "Input - multi-line text",
    value: FlowNodeType.InputMultiLineText,
  },
  {
    label: "Transition Screen",
    value: FlowNodeType.Transition,
  },
  // {
  //   label: "Input - calendar",
  //   value: FlowNodeType.InputCalendar,
  // },
  // {
  //   label: "Input - slider",
  //   value: FlowNodeType.InputSlider,
  // },
];

const getDefaultValues = (values) => {
  const pageSettingsJSON = values?.pageSettings;
  const pageSettings = pageSettingsJSON ? JSON.parse(pageSettingsJSON) : {};

  return {
    type: values?.type || FlowNodeType.InputOneLineText,
    isRequired:
      typeof values?.isRequired !== "undefined" ? !!values.isRequired : false,
    maxLength: values?.maxLength ?? "3",
    inputWidth: values?.inputWidth ?? "",
    inputHeight: values?.inputHeight ?? "",
    transitionScreen_buttonTextOverride:
      pageSettings?.transitionScreen_buttonTextOverride ?? "",
    conditions: pageSettings?.conditions ?? "{}",
  };
};

const ConditionsSettings = ({ control }) => {
  const { field } = useController({
    name: "conditions",
    control,
    defaultValue: JSON.stringify({}),
  });

  const [conditions, setConditions] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.conditions) {
        return val.conditions;
      }
      return [];
    } catch (error) {
      return [];
    }
  });

  const [showSkip, setShowSkip] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.showSkip) {
        return val.showSkip;
      }
      return "skip";
    } catch (error) {
      return "skip";
    }
  });

  const [anyAll, setAnyAll] = useState(() => {
    try {
      const val = JSON.parse(field.value);
      if (val?.anyAll) {
        return val.anyAll;
      }
      return "any";
    } catch (error) {
      return "any";
    }
  });

  useEffect(() => {
    try {
      const parsed = JSON.parse(field.value);
      setConditions(parsed?.conditions || []);
      setShowSkip(parsed?.showSkip || "skip");
      setAnyAll(parsed?.anyAll || "any");
    } catch (error) {
      setConditions([]);
      setShowSkip("skip");
      setAnyAll("any");
    }
  }, [field.value]);

  useEffect(() => {
    field.onChange(
      JSON.stringify({
        conditions,
        showSkip,
        anyAll,
      })
    );
  }, [conditions, showSkip, anyAll]);

  const addCondition = () => {
    setConditions((prev) => {
      return [
        ...prev,
        {
          id: uuidv4(),
          propertyId: "",
          verb: "is",
          match: "exactly",
          propertyValue: "",
        },
      ];
    });
  };

  const removeCondition = (id) => {
    setConditions((prev) => {
      return prev.filter((condition) => condition.id !== id);
    });
  };

  return (
    <Section>
      <Text as="p" variant="bodyMd">
        Be default, the page is always shown. You can choose to show/skip it
        based on certain conditions.
      </Text>
      <InlineStack gap="100" align="start" blockAlign="center" wrap={true}>
        <Select
          label=""
          labelHidden
          options={[
            { label: "Skip", value: "skip" },
            { label: "Show", value: "show" },
          ]}
          value={showSkip}
          onChange={(v) => setShowSkip(v)}
        />{" "}
        the page if
        <Select
          label=""
          labelHidden
          options={[
            { label: "any", value: "any" },
            { label: "all", value: "all" },
          ]}
          value={anyAll}
          onChange={(v) => setAnyAll(v)}
        />{" "}
        of the following conditions are met:
      </InlineStack>
      <Button variant="secondary" onClick={addCondition} icon={PlusIcon}>
        Add Condition
      </Button>
      {conditions.map((condition) => {
        return (
          <BlockStack key={condition.id} gap="200">
            <TextField
              label="Property ID"
              labelHidden
              autoComplete=""
              value={condition.propertyId}
              onChange={(v) => {
                setConditions((prev) => {
                  return prev.map((c) => {
                    if (c.id === condition.id) {
                      return {
                        ...c,
                        propertyId: v,
                      };
                    }
                    return c;
                  });
                });
              }}
              placeholder="Property ID"
            />
            <InlineStack
              gap="100"
              align="start"
              blockAlign="center"
              wrap={true}
            >
              <Select
                label="verb"
                labelHidden
                options={[
                  { label: "is", value: "is" },
                  { label: "is not", value: "is_not" },
                ]}
                value={condition.verb}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          verb: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
              />
              <Select
                label="match"
                labelHidden
                options={[
                  { label: "exactly", value: "exactly" },
                  { label: "at least", value: "at_least" },
                  { label: "one of", value: "one_of" },
                  { label: "provided", value: "provided" },
                ]}
                value={condition.match}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          match: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
              />
            </InlineStack>
            {condition.match !== "provided" && (
              <TextField
                label="Property Value(s)"
                autoComplete=""
                labelHidden
                value={condition.propertyValue}
                onChange={(v) => {
                  setConditions((prev) => {
                    return prev.map((c) => {
                      if (c.id === condition.id) {
                        return {
                          ...c,
                          propertyValue: v,
                        };
                      }
                      return c;
                    });
                  });
                }}
                placeholder="Property Value(s)"
                helpText="If you want to include multiple values, separate them with a comma."
              />
            )}
            <Button
              variant="secondary"
              tone="critical"
              icon={DeleteIcon}
              onClick={() => removeCondition(condition.id)}
            >
              Remove Condition
            </Button>
          </BlockStack>
        );
      })}
    </Section>
  );
};

const GeneralSettings = ({ control }) => {
  return (
    <Section>
      <Controller
        control={control}
        name="maxLength"
        render={({ field: { ref, ...field } }) => (
          <Select
            label="Auto Transition After:"
            options={[
              { label: "never", value: "0" },
              { label: "1 second", value: "1" },
              { label: "2 seconds", value: "2" },
              { label: "3 seconds", value: "3" },
              { label: "5 seconds", value: "5" },
              { label: "7 seconds", value: "7" },
              { label: "10 seconds", value: "10" },
              { label: "15 seconds", value: "15" },
              { label: "20 seconds", value: "20" },
              { label: "30 seconds", value: "30" },
            ]}
            value={field.value}
            name={field.name}
            onChange={field.onChange}
            helpText="Automatically proceed to the next question after the specified time."
          />
        )}
      />

      <Controller
        control={control}
        name="inputHeight"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Image Height"
            autoComplete="off"
            placeholder="leave blank for auto-sizing"
            value={field.value}
            name={field.name}
            type="text"
            onChange={field.onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="inputWidth"
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Image Width"
            autoComplete="off"
            placeholder="leave blank for auto-sizing"
            value={field.value}
            name={field.name}
            type="text"
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="isRequired"
        render={({ field: { value, ...field } }) => (
          <Checkbox
            label="Show Next Question Button"
            checked={value}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="transitionScreen_buttonTextOverride"
        render={({ field: { value, onChange, ...field } }) => (
          <TextField
            label="Button Text Override"
            value={value}
            onChange={onChange}
            autoComplete="off"
            placeholder="leave empty to use default text"
            {...field}
          />
        )}
      />
    </Section>
  );
};

const TransitionOptions = () => {
  const form = useOptionsForm({
    getDefaultValues,
    pageSettingsKeys: ["transitionScreen_buttonTextOverride", "conditions"],
    type: "flowNode",
  });
  const control = form.control;
  const [settings, setSettings] = useState(0);

  return (
    <SettingsContainer>
      <BlockStack align="start" inlineAlign="stretch" gap="200">
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 1 ? "critical" : undefined}
          disclosure={settings === 1 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 1 ? 0 : 1))}
          icon={SettingsIcon}
        >
          General Settings
        </Button>
        <Collapsible
          open={settings === 1}
          id="collapsible-1"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <GeneralSettings control={control} />
        </Collapsible>
        <Button
          variant="monochromePlain"
          fullWidth
          textAlign="left"
          tone={settings === 2 ? "critical" : undefined}
          disclosure={settings === 2 ? "down" : "up"}
          onClick={() => setSettings((settings) => (settings === 2 ? 0 : 2))}
          icon={AppsIcon}
        >
          Skip/Show Conditions
        </Button>
        <Collapsible
          open={settings === 2}
          id="collapsible-2"
          transition={{ duration: "250ms", timingFunction: "ease-in-out" }}
        >
          <ConditionsSettings control={control} />
        </Collapsible>
      </BlockStack>
    </SettingsContainer>
  );
};

export default TransitionOptions;

const SettingsContainer = styled.div`
  padding: 15px 5px;

  & .Polaris-Button__Content {
    width: 100%;
    justify-content: space-between !important;
  }
`;
