import { useEffect, useRef, useCallback } from "react";
import equal from "fast-deep-equal";
import { produce } from "immer";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { debounce } from "lodash";

import { useFlow, useSdk } from "~/hooks";
import { useToast } from "~/lib";

interface OptionsFormProps {
  getDefaultValues(flowNode?: any): any;
  formatValues?(values: any): any;
  pageSettingsKeys?: string[];
  type: "flow" | "flowNode";
  debounceTime?: number;
}

export const useOptionsForm = ({
  getDefaultValues,
  formatValues,
  pageSettingsKeys = [],
  type,
  debounceTime = 500, // Default debounce of 500ms
}: OptionsFormProps) => {
  const { data, flowNode, setStatus } = useFlow();

  const queryClient = useQueryClient();
  const sdk = useSdk();
  const toast = useToast();

  const currentFlow = data?.flows?.[0];

  const previousValues = useRef<any>(null);
  const debouncedSaveRef = useRef<any>(null);

  const formProps = useForm({
    defaultValues: getDefaultValues(type === "flow" ? currentFlow : flowNode),
  });
  const { reset, watch } = formProps;

  const values = watch();

  const formatForMutation = (values) => {
    if (pageSettingsKeys.length) {
      let pageSettings = {};
      if (values.pageSettings) {
        pageSettings = JSON.parse(values.pageSettings);
      }
      const otherValues = { ...values };

      pageSettingsKeys.forEach((key) => {
        if (values?.[key] !== undefined && values?.[key] !== null) {
          pageSettings[key] = values[key];
          delete otherValues[key];
        }
      });

      return {
        ...otherValues,
        pageSettings: JSON.stringify(pageSettings),
      };
    }

    return values;
  };

  const flowMutation = useMutation(
    ({ id, values }: { id: string; values: any }) => {
      return sdk.updateOneFlow({
        input: {
          id,
          update: formatValues
            ? formatValues(formatForMutation(values))
            : formatForMutation(values),
        },
      });
    },
    {
      onMutate: async ({ values }) => {
        const key = ["container", { id: data?.id }];

        await queryClient.cancelQueries(key);

        const previous = queryClient.getQueryData(key);

        queryClient.setQueryData(key, (old: any) =>
          produce(old, (draft) => {
            draft.flows[0] = { ...draft.flows[0], ...values };
          })
        );

        return { previous };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["container", { id: data?.id }]);
        setStatus("saved");
      },
      onError: (e: any, _, context: any) => {
        reset(getDefaultValues(context?.previous?.flows?.[0]));
        queryClient.setQueryData(
          ["container", { id: data?.id }],
          context?.previous
        );
        setStatus("error");
        toast({
          error: true,
          content: e?.messages?.[0] || e?.message || "Error saving settings.",
        });
      },
    }
  );

  const flowNodeMutation = useMutation(
    ({ id, values }: { id: string; values: any }) => {
      return sdk.updateOneFlowNode({
        input: {
          id,
          update: formatValues
            ? formatValues(formatForMutation(values))
            : formatForMutation(values),
        },
      });
    },
    {
      onMutate: async ({ id, values }) => {
        const key = ["container", { id: data?.id }];

        await queryClient.cancelQueries(key);

        const previous = queryClient.getQueryData(key);

        queryClient.setQueryData(key, (old: any) =>
          produce(old, (draft) => {
            const index = draft.flows?.[0]?.nodes?.findIndex(
              (node) => node?.id === id
            );

            if (index !== -1) {
              draft.flows[0].nodes[index] = {
                ...draft.flows[0].nodes[index],
                ...values,
              };
            }
          })
        );

        return { previous };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["container", { id: data?.id }]);
        setStatus("saved");
      },
      onError: (e: any, { id }, context: any) => {
        reset(
          getDefaultValues(
            context?.previous?.flows?.[0]?.nodes?.find(
              (node) => node?.id === id
            )
          )
        );
        queryClient.setQueryData(
          ["container", { id: data?.id }],
          context?.previous
        );
        setStatus("error");
        toast({
          error: true,
          content: e?.messages?.[0] || e?.message || "Error saving settings.",
        });
      },
    }
  );

  // Create a debounced save function
  const saveChanges = useCallback(
    (currentValues) => {
      setStatus("saving");

      if (type === "flow") {
        if (currentFlow?.id) {
          flowMutation.mutate({ id: currentFlow.id, values: currentValues });
        }
      } else if (flowNode?.id) {
        flowNodeMutation.mutate({ id: flowNode.id, values: currentValues });
      }

      previousValues.current = currentValues;
    },
    [type, currentFlow, flowNode, flowMutation, flowNodeMutation, setStatus]
  );

  // Create and store the debounced function with useRef to maintain the same function reference
  useEffect(() => {
    debouncedSaveRef.current = debounce(saveChanges, debounceTime);

    // Cleanup function to cancel any pending debounced calls when unmounting
    return () => {
      debouncedSaveRef.current?.cancel();
    };
  }, [saveChanges, debounceTime]);

  // Watch for changes and trigger debounced save
  useEffect(() => {
    const currentValues = formatForMutation(values);

    if (!previousValues.current) {
      previousValues.current = currentValues;
    } else if (!equal(currentValues, previousValues.current)) {
      // Visual feedback can be immediate
      setStatus("saving");

      // But the actual save is debounced
      debouncedSaveRef.current(currentValues);
    }
  }, [values, formatForMutation]);

  return formProps;
};
